<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="100"
      color="#0d2573"
      class="px-desktop"
    >
      <base-img
        :src="require('@/assets/img/singularegestao2.png')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="80"
        width="100%"
      />

      <base-img
        :src="require(`@/assets/img/singularegestao1.png`)"
        contain
        max-width="230"
        width="100%"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-md-and-down"
          optional
          background-color="transparent"
          dark
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="item.route"
            :exact="item.text === 'Início'"
            :ripple="false"
            class="font-weight-bold text--white"
            min-width="96"
            text
          >
            <span>{{ item.text }}</span>
          </v-tab>
          <v-btn
            class="mt-1"
            outlined
            color="white"
            text
            link
            href="https://singularegestaoapp.com21.com.br/frontend/public/#/login"
            target="_blank"
          >
            Área do cliente
          </v-btn>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        dark
        @click="drawer = !drawer"
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      floating
      right
      color="#0d2573"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="lgroup"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.route"
          >
            <v-list-item-title class="font-weight-bold text--white v-tab">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
          <v-btn
            class="mt-5"
            outlined
            color="white"
            text
            link
            href="https://singularegestaoapp.com21.com.br/frontend/public/#/login"
            target="_blank"
            width="100%"
          >
            Área do cliente
          </v-btn>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<style>
  @media not screen and (max-width: 1150px) {
    .px-desktop {
      padding: 0px 8% 0px 8%;
    }
  }
</style>
<script>
  export default {
    name: 'HomeAppBar',

    data: () => ({
      drawer: false,
      lgroup: null,
      items: [
        {
          route: '/',
          text: 'Início',
        },
        {
          route: '/#about-us',
          text: 'Quem somos',
        },
        {
          route: '/#features',
          text: 'Empresa',
        },
        {
          route: 'servicos',
          text: 'Serviços',
        },
        {
          route: '#contact-us',
          text: 'Contato',
        },
      ],
    }),
  }
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
